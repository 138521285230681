import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Cards } from '../components/Cards';
import MainFooter from '../components/MainFooter';
import MainHeader from '../components/MainHeader';
import { motion } from 'framer-motion'
function SubTwoTitleContentPage() {
  let { subtitleId, twosubtitleId } = useParams();
  let { titleId } = useParams();
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  const [link, setLink] = useState<any>("0");
  const [components, setComponents] = useState([]);
  const getData = () => {
    setLink(twosubtitleId);
    if (twosubtitleId === "0") {
      console.log("zoooo")
      axios.get(`${process.env.REACT_APP_DATABASE_API_URL}contents/contents_list_guest_alt/${subtitleId}`)
        .then(res => res.data)
        .then(data => {
          console.log("rrrr", data['data'])
          setComponents(data['data']);
        })
    } else {
      console.log("muuuuu")
      axios.get(`${process.env.REACT_APP_DATABASE_API_URL}contents/contents_list_guest_alt_icerik/${twosubtitleId}`)
      .then(res => res.data)
      .then(data => {
        console.log("rrrr", data['data'])
        setComponents(data['data']);
      })
    }
  }
  useEffect(() => {
    console.log("1231232");
    getData()
  }, [twosubtitleId])
  var linkci = "";
  var resimonek = "";
  return <>
    <MainHeader />
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='d-flex flex-column   flex-column-fluid '
      style={{
        backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/3sayfa.webp`)})`,
        backgroundPosition: 'center', backgroundRepeat: 'repeat', backgroundSize: 'cover'
      }} >
      <div className="text-center text-white   mt-20" >
        <div className="row mx-auto  d-flex justify-content-center mt-8" >
          {components.map((val: any, i) => {
            if (link === "0") {
              linkci = `/subtitle/${titleId}/${subtitleId}/${val['id']}`;
              resimonek = "";
            } else {
              linkci = val['id'];
              resimonek = "images/"
            }
            return (
              <div key={i} className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3    " >
                <Cards
                  baslik={`${val['name']}`}
                  sira={val['id']}
                  img={val['local_image'] === null ? '' : `${resimonek}${val['icon']}`}
                  url={linkci}
                  // url={`../subtitle/${titleId}/${subtitleId}/${val['id']}`}

                  statement={`${val['statement']}`}
                  className=' w-75  px-0 '
                  chartColor='primary'
                  chartHeight='#fff' />
              </div>
            )
          }
          )}
        </div>

      </div>
      <MainFooter />
    </motion.div>
  </>
}

export default SubTwoTitleContentPage
