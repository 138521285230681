import {React, useState, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, Routes, Route, useNavigate, useParams} from 'react-router-dom'
import WeatherComponent from './WeatherComponent'

function MainHeader() {
  let {titleId, subtitleId, contentId} = useParams()
  const [link, setLink] = useState('')

  function isimci(deger) {
    if (deger == 1) {
      return "#fff";
    } else if (deger == 2) {
      return "#e0a800"
    } else {
      return "#e0a800";
    }
  }


  useEffect(() => {
    let linkim = ''
    if (titleId === undefined) {
      linkim = ''
    } else if (subtitleId === undefined) {
      linkim = '/'
    } else if (subtitleId === '0' && contentId === undefined) {
      linkim = '/'
    } else if (contentId === undefined) {
      linkim = '/subtitle/' + titleId
    } else if (contentId !== undefined) {
      linkim = '/subtitle/' + titleId + '/' + subtitleId
    }
    setLink(linkim)
  }, [useParams])

  // const [date, setDate] = useState(new Date())
  // function refreshClock() {
  //   setDate(new Date())
  // }
  // useEffect(() => {
  //   const timerId = setInterval(refreshClock, 1000)
  //   return function cleanup() {
  //     clearInterval(timerId)
  //   }
  // }, [])
  return (
    // <nav className='navbar navbar-light   bg-light sticky-top' style={{backgroundColor: "#e3f2fd"}}>
    //   <a href='/' className='navbar-brand ms-20 '>
    //     <img className=''style={{width:'160px'}} src={toAbsoluteUrl('/media/logos/otel.png')} />
    //   </a>
    //   <form className='me-20 form-inline'>
    //     <span style={{fontSize: '2rem'}}>{date.toLocaleTimeString()}</span>
    //   </form>
    // </nav>
    // <nav class="d-flex justify-content-around" style={{backgroundColor: "transparent",position:'absolute', zIndex: 100}}>
    //   <div className="">aaa</div>
    //   <div className="">bbb</div>
    //   <div className="">ccc</div>
    // </nav>
    <nav
      style={{height: '120px'}}
      className='navbar fixed-top navbar-expand justify-content-between  '
    >
      <div className='justify-content-between d-flex'>
        <div className=''>
          <Link to={'/'} className='navbar-brand float-left' href='/'>
            <img
              style={{width: 'auto', height: '90px', filter: ' drop-shadow(4px 4px 4px #000 )'}}
              className=' float-left mx-auto d-block ms-10 '
              src={toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/solustbuyuk.webp`)}
            />
          </Link>
        </div>
        <div className='mt-5'>
          {link !== '' && (
            <Link to={`${link}`}>
              <img
                style={{width: '60px', height: '60px'}}
                src={toAbsoluteUrl('/media/icons/duotune/arrows/arr096.png')}
                className='d-block mt-13 '
              ></img>
            </Link>
          )}
        </div>
      </div>
      <div className='form-inline me-20 d-none d-sm-block   text-white'>
        {<WeatherComponent />}
        {/* <span style={{fontSize: '2rem'}}>{date.toLocaleTimeString()}</span> */}
      </div>
    </nav>
  )
}

export default MainHeader
