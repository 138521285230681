import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import {User, UsersQueryResponse} from './_models'

//const API_URL = process.env.REACT_APP_THEME_API_URL
const API_DB_URL = process.env.REACT_APP_DATABASE_API_URL

const GET_BG_URL = `${API_DB_URL}backgrounds`

const getBg = (query: string): Promise<UsersQueryResponse> => {
  const all = axios
    .post(`${GET_BG_URL}/backgrounds_list/?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return all
}
const getBgMain = (query: string): Promise<UsersQueryResponse> => {
  const all = axios
    .post(`${GET_BG_URL}/backgrounds_list_main/${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
    
  return all
}

const getBgById = (id: ID): Promise<User | undefined> => {
  const all = axios
    .get(`${GET_BG_URL}/single_backgrounds/${id}`)
    //.then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)

  return all
}

const createComponent = (user: User): Promise<User | undefined> => {

  const all = axios
    .post(`${GET_BG_URL}/create_component`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
  return all
}

const updateBg = (user: User): Promise<User | undefined> => {
    const all=(
    axios
      .post(`${GET_BG_URL}/updateBg`, user)
      //.then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  )
  
  return all;
}


const getSubtitles=(titleId:any)=>{
  const all= axios
  .post(`${GET_BG_URL}/subtitles_list/${titleId}`)
  return all;
}



export {
  getBg,
  getBgMain,
  getBgById,
  createComponent,
  updateBg,
  getSubtitles
}
