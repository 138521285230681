/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div

      className='d-flex flex-column flex-column-fluid '
      style={{
        backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/login1.jpg`)})`,
        backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',

      }}

    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid  '>
        {/* begin::Logo */}
        <a href='#' className=''>
          <img alt='Logo' style={{ width: '100%', height: '260px', filter: ' drop-shadow(4px 4px 4px #000 )' }}
            src={toAbsoluteUrl(process.env.REACT_APP_MEDIA_API_URL + '/resimler/solustbuyuk.webp')}
            className='' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div style={{ borderRadius: '20px' }} className='w-lg-500px h-auto bg-white shadow-sm p-10 mb-10'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
