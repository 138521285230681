import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import WeatherComponent from './WeatherComponent'
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap';
function MainFooter() {
    let { titleId, subtitleId, contentId } = useParams();
    var tarih = new Date();
    let gunler = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let gun = gunler[tarih.getDay()]
    var dayNames = new Array("Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi");
    let gunTr = dayNames[tarih.getDay()]
    let aylar = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let ay = aylar[tarih.getMonth()];
    let year = tarih.getFullYear();
    let history = useNavigate();
    const [link, setLink] = useState('');

    const [date, setDate] = useState(new Date())
    function refreshClock() {
        setDate(new Date())
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 10000)
        return function cleanup() {
            clearInterval(timerId)
        }
    }, [])
    useEffect(() => {
        let linkim = "";
        if (titleId === undefined) {
            linkim = "";
        } else if (subtitleId === undefined) {
            linkim = "/";
        }
        else if (subtitleId === '0' && contentId === undefined) {
            linkim = "/";
        } else if (contentId === undefined) {
            linkim = "/subtitle/" + titleId;
        } else if (contentId !== undefined) {
            linkim = "/subtitle/" + titleId + "/" + subtitleId;
        }
        setLink(linkim);
    }, [useParams])
    function isimci(deger: any) {
        if (deger == 1) {
            return "#fff";
        } else if (deger == 2) {
            return "#e0a800"
        } else {
            return "#e0a800";
        }
    }
    const webtur = isimci(process.env.REACT_APP_WEBTUR);
    return (
        // <footer className=" navbar-expand sticky-bottom footer  text-white  d-inline-flex " style={{ backgroundColor: "transparent", position: 'absolute', zIndex: 100 }}>

        //     <span className=" ms-20  " style={{ fontSize: '2rem' }}>{`${tarih.getDate()} ${ay} ${year} ${gun}`}</span>

        //     {<WeatherComponent />}


        // </footer>
        <footer className="navbar fixed-bottom navbar-expand justify-content-between text-center    pb-0">
            <div className="">
                <div className="row">

                    <div className="col-6">
                        <a className="navbar-brand float-left" href="#">
                            <img style={{ width: '50px', height: '50px' }} className='rounded float-left mx-auto d-block ms-10 mb-2' src={toAbsoluteUrl('/media/logos/key-1.png')} />
                        </a>
                    </div>

                </div>

            </div>
            <div className="col-6">

                {/* <button className='btn btn-warning' onClick={() => history(`${link}`)}>{'<'}</button> */}

                {titleId !== undefined ? <>
                    {link !== "" && (<Link to={`${link}`}><img style={{ width: '60px', height: '60px' }} src={toAbsoluteUrl('/media/icons/duotune/arrows/arr096.png')} className='ms-5'></img></Link>)}</> :
                    <div className=''>
                        <div className="d-none d-sm-block  text-white text-center" >
                            <span className="  text-center " style={{ fontSize: '2rem', fontFamily: 'Keiner-Medium', color: webtur, textShadow: '2px 2px 4px #515151' }}>{`${gun} / ${gunTr}`} <br />
                                {date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </span>

                        </div>
                    </div>}
            </div>
            {/* gün ve saat yazısı start */}



            {/* gün ve saat yazısı start */}
            <div className=" pe-20">
                <a href={`${process.env.REACT_APP_ANASAYFA}auth`}><img style={{ width: '120px', height: '80px', filter: ' drop-shadow(4px 4px 4px #000 )' }} className='rounded float-left mx-auto d-block ms-10 mb-2' src={toAbsoluteUrl(process.env.REACT_APP_MEDIA_API_URL + "resimler/sagaltlogo.webp")} /></a>
            </div>

        </footer>
    )
}

export default MainFooter