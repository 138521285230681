import React, { useEffect, useState } from 'react'
import Weather from './Weather'

function WeatherComponent() {
  let [error, setError] = useState(false)
  // lat [(process.env.REACT_APP_WEBTUR==='1'?41.031295785413036:41.036332041555646)]
  // long [(process.env.REACT_APP_WEBTUR==='1'?28.974149546300936:28.98638386442861)]
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [data, setData] = useState([]);
  function isimci(deger) {
    if (deger == 1) {
      return "İstanbul";
    } else if (deger == 2) {
      return "İstanbul"
    } else {
      return "İstanbul";
    }
  }
  let [currentWeather, setCurrentWeather] = useState({
    temp: '1.12',
    feels_like: '-1',
    description: 'snowing',
    icon: 'clear',
    name: isimci(process.env.REACT_APP_WEBTUR),
    lat: lat,
    lon: long,
  })
  useEffect(() => {
    setLat(41.036332041555646);
    setLong(28.98638386442861);
  }, [])
  let [url, setUrl] = useState(
    `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&appid=${process.env.REACT_APP_API_KEY}&units=metric`
  )



  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        // setLat(position.coords.latitude);
        // setLong(position.coords.longitude);
      });

      await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&appid=${process.env.REACT_APP_API_KEY}&units=metric`)
        .then(res => res.json())
        .then(result => {
          setData(result)
          let { description, icon } = result.weather[0]
          let { temp, feels_like } = result.main
          setCurrentWeather({
            temp: temp.toFixed(1),
            feels_like,
            description,
            icon,
            name: `${result.name} `,
            lat: lat,
            lon: long,
          })

        });
    }
    fetchData();
  }, [lat, long])



  return (
    <div className=' mb-5'>
      {typeof currentWeather != 'undefined' ? (
        <Weather weatherData={currentWeather} />
      ) : (
        <div>Problem with server from weather</div>
      )}
    </div>
  )
}

export default WeatherComponent
