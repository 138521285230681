
/* eslint-disable jsx-a11y/anchor-is-valid  */
import axios, { AxiosResponse } from 'axios';
import { SetStateAction, useEffect, useState, useMemo } from 'react'
import { Outlet, Route, Router, Routes } from 'react-router-dom'
//apk design
// import {Registration} from './components/Registration'
// import {ForgotPassword} from './components/ForgotPassword'
// import {Login} from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { MixedWidget7 } from '../../../_metronic/partials/widgets';
import { Cards } from './components/Cards';
import WeatherComponent from './components/WeatherComponent';
import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import './styles/cardhover.css'
import SubTitleContentPage from './subtitle/SubTitleContentPage';
import SubTwoTitleContentPage from './subtitle/SubTwoTitleContentPage';
import SubTitlePage from './subtitle/SubTitlePage';
import ContentPage from './subtitle/ContentPage';
import { getBgMain } from '../background/bg-list/core/_requests';
import { UsersQueryResponse } from '../background/bg-list/core/_models';
//import {motion} from 'framer-motion'
const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  const [components, setComponents] = useState([]);
  const getData = () => {
    axios.get(`${process.env.REACT_APP_DATABASE_API_URL}cards/components_list_main`)
      .then(res => res.data)
      .then(data => {
        setComponents(data);

      })
  }
  var tarih = new Date();
  let gunler = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let gun = tarih.getDay().toString()

  const [haftagun, setHaftagun] = useState(gun);
  const [bgImage, setBgImage] = useState('bos.jpg?stt=123123');

  const Images = () => {
    getBgMain(gun)
      .then((res: any) => {
        setBgImage(res);
      })
  }
  useEffect(() => {
    getData()

  }, [])
  useEffect(() => {

    const interval = setInterval(() => {
      setHaftagun(new Date().getDay().toString());

    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);
  useMemo(() => {
    Images();
  }, [haftagun])
  function isimci(deger: any) {
    if (deger == 1) {
      return "#fff";
    } else if (deger == 2) {
      return "#e0a800"
    } else {
      return "#e0a800";
    }
  }
  function anaisim(deger: any) {
    if (deger == 1) {
      return "The Marmara Taksim Interactive Hotel Kiosk";
    } else if (deger == 2) {
      return "The Marmara Pera Interactive Hotel Kiosk"
    } else {
      return "The Demo Interactive Hotel Kiosk";
    }
  }
  const webtur = isimci(process.env.REACT_APP_WEBTUR)
  return (
    <>
      <MainHeader />

      <div className='d-flex    flex-column-fluid '
        // istanbul.jpg
        style={{
          backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}${bgImage}`)})`,
          backgroundPosition: 'center', backgroundRepeat: 'repeat', backgroundSize: 'cover'

        }} >

        {/* initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} */}
        <div className="text-center  text-white   mt-20" >
          <div style={{ color: webtur }} className="row     mx-auto d-flex justify-content-center px-4" >
            <span style={{ color: webtur, fontSize: '26px', fontFamily: 'Keiner-Medium', textShadow: '2px 2px 4px #515151', marginRight: '12rem', marginTop: '-3rem' }} className='fw-bold d-none d-sm-block    text-center '>{anaisim(process.env.REACT_APP_WEBTUR)}</span>

            {/* <table className="table"> */}
            {/* <tbody> */}
            {components.map((val: any, i) => (
              val.map((a: any, i: any) => (
                <div key={i} className="col-sm-4 col-md-4 col-lg-4 col-xl-2 col-xxl-2 mt-4 " >
                  <Cards
                    baslik={`${a['name']}`}
                    sira={a['id']}
                    img={`${a['icon']}`}
                    url={`subtitle/${a['id']}${a['sub_title_active'] === 0 ? '/0' : ''}`}
                    className='w-auto     mb-2 '
                    chartColor='primary'
                    titleColor={isimci(process.env.REACT_APP_WEBTUR)}
                    chartHeight={''} />
                </div>
              ))
            ))}
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  )
}
const MainPage = () => (

  <Routes>
    <Route >
      <Route path='subtitle/:titleId' element={<SubTitlePage />} />
      <Route path='subtitle/:titleId/:subtitleId' element={<SubTitleContentPage />} />
      <Route path='subtitle/:titleId/:subtitleId/:twosubtitleId' element={<SubTwoTitleContentPage />} />
      <Route path='subtitle/:titleId/:subtitleId/:twosubtitleId/:contentId' element={<ContentPage />} />
      <Route index element={<AuthLayout />} />
    </Route>
  </Routes>

)
export { MainPage }
function createTimer(): () => void {
  throw new Error('Function not implemented.');
}

