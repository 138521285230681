import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuInnerWithSub title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard'  menuPlacement='bottom-start'
        menuTrigger='click'>
      <MenuItem to='/guest/backgorund' title='Arka Plan ' icon='/media/icons/duotune/general/gen025.svg' />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='KARTLAR'
        to='/cards/components'
        menuPlacement='bottom-start'
        menuTrigger='click'>
        <MenuItem to='/cards/components/card' title='Ana Başlıklar' icon='/media/icons/duotune/general/gen025.svg' />
        <MenuItem to='/cards/components/subtitle' title='Alt Başlıklar' icon='/media/icons/duotune/general/gen022.svg' />
        <MenuItem to='/cards/components/twosubtitle' title='2. Alt Başlıklar' icon='/media/icons/duotune/general/gen022.svg' />
        <MenuItem to='/cards/components/content' title='İçerik' icon='/media/icons/duotune/general/gen005.svg' />
      </MenuInnerWithSub>


    </>
  )
}
