/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { imageUrl, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { Dropdown1 } from './../../../../_metronic/partials/content/dropdown/Dropdown1';
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import axios from 'axios';
import './../styles/cardhover.css'
import { Link, Route } from 'react-router-dom';
import FirstPage from '../subtitle/SubTitlePage';
type Props = {
    className: string
    chartColor: string
    chartHeight: string
    baslik: string
    sira: number
    img: string
    url: string
    statement?: string
    titleColor?: string
}

const Cards: React.FC<Props> = ({ className, chartColor, titleColor = '#fff', baslik, sira, img, url, statement }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const addDefaultSrc = (ev: any) => {
        ev.target.src = `${process.env.REACT_APP_MEDIA_API_URL}error.png`
    }
    return (
        <div className=' mt-5' key={sira}>
            {/* borderRadius: "5px", width: '15rem', height: '15rem' ,background:'#F5FDF7', */}
            <div style={{ borderRadius: "5px", width: '9rem', height: '12.3rem', background: 'rgba(255,255,255,0.3)', backdropFilter: 'blur(7px)' }} className={` d-flex flex-column mx-auto  ${className}`}>
                <Link key={sira} to={`${url}`} >
                    <div className='   '>
                        <img className='p-3 mt-n2' style={{ borderRadius: "5px", width: '10rem', height: '9rem', objectFit: 'contain' }} onError={addDefaultSrc} src={`${process.env.REACT_APP_MEDIA_API_URL}${img}`}
                        />
                        <div className='card-title  '>
                            <span style={{
                                fontSize: '1.2rem', flex: '2', color: titleColor, fontFamily: 'Keiner-Medium', textShadow: '0.8px 0.8px 1px  #515151'
                            }} className='card-title text-center '>
                                <b  >{baslik}
                                </b>
                                <br />
                                {/* {statement ? <span style={{fontSize:'15px'}} className=' '>{statement}</span> : ''} */}
                            </span>
                            {/* <text className='text-center'>alt başlık açıklaması</text> */}
                        </div>
                    </div>
                    {/* end::Body */}
                </Link>
            </div>

        </div>
    )
}



export { Cards }
