import React, { useEffect, useRef, useState } from 'react'
import MainFooter from '../components/MainFooter'
import MainHeader from '../components/MainHeader'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import "yet-another-react-lightbox/styles.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "react-image-gallery/styles/scss/image-gallery.scss";


import { initLightboxJS } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import { SlideshowLightbox } from 'lightbox.js-react'

import { Image } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'


import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./slaytim.css";

import "./aaa.css";


import { FreeMode, Navigation, Thumbs } from "swiper";


function ContentPage() {
  const photos = () => {

    images.map((res: any, i: number) => {
      SetImages_list(state => [...state, { original: `${process.env.REACT_APP_MEDIA_API_URL}/images/${res['icon']}`, thumbnail: `${process.env.REACT_APP_MEDIA_API_URL}/images/${res['icon']}` }])
    })
  }
  interface EnumServiceGetOrderBy {
    original: string; thumbnail: string;
  }
  let { subtitleId } = useParams();
  let { titleId } = useParams();
  let { contentId } = useParams();
  let { twosubtitleId } = useParams();

  const [open, setOpen] = React.useState(false);

  const [index, setIndex] = React.useState(-1);
  const [content, setContent] = useState<any>({});

  const [images, SetImages] = useState([]);
  const [sttnaberlo, setSttnaberlo] = useState(500);

  const [images_list, SetImages_list] = useState<EnumServiceGetOrderBy[]>([{ original: `${process.env.REACT_APP_MEDIA_API_URL}/bos.jpg`, thumbnail: `${process.env.REACT_APP_MEDIA_API_URL}/bos.jpg` }]);
  const [video, setVideo] = useState('');
  const [iframeVideo, setIframeVideo] = useState('');
  // http://localhost/hotel_project/api/app/media/videos/${content["iframe_video"]}
  // const [ucak, setucak] = useState({});
  // const GetFly = () => {
  //   axios.post(`${process.env.REACT_APP_DATABASE_API_URL}iframe_getir`)
  //     .then(res => setucak(res.data))
  // }
  const getData = () => {

    if (contentId === "0") {
      contentId = twosubtitleId
    }
    axios.post(`${process.env.REACT_APP_DATABASE_API_URL}contents/contents_list_details/${titleId}/${subtitleId}/${contentId}`)
      .then(res => res.data)
      .then(data => {
        console.log("nonniii", data)
        setContent(data.data);
        setTimeout(function () {
          SetImages_list([]);
          SetImages(JSON.parse(data.data.local_image))
          setVideo(`${process.env.REACT_APP_MEDIA_API_URL}videos/${data.data.local_video}`)
          setIframeVideo(data.iframe_video);

        }, 1000);
        // window.scrollTo(0, 30);
      })
  }

  const Videocum = ({ deger }: any) => {
    return (<video loop className='rounded   bg-dark mt-5 ' width="100%" height={`${sttnaberlo / 2.1}px`} controls  ><source src={`${process.env.REACT_APP_MEDIA_API_URL}videos/${deger}`} type="video/mp4" /> </video>)
  }
  useEffect(() => {
    getData()
    //GetFly();

    var body = document.body,
      html = document.documentElement;

    var height = Math.max(body.offsetHeight,
      html.clientHeight, html.offsetHeight);
    console.log("yükseklik", body.offsetHeight,
      html.clientHeight, html.offsetHeight, height);
    setSttnaberlo(height - 190)
  }, [])
  useEffect(() => {
    photos()
  }, [images])
  function createMarkup() { return { __html: 'First &middot; Second' }; };


  const [thumbsSwiper, setThumbsSwiper] = useState();


  const imagescc = [
    { src: 'https://fastly.picsum.photos/id/218/800/800.jpg?hmac=_dXixKudzwdRq89L8ehYa0w3ue7FnfZUMIlDpLnMjsU' },
    { src: 'https://fastly.picsum.photos/id/445/800/800.jpg?hmac=xfeRj__e_T3gSpsqv7J4IfuIQDHH45_b6oHi-v5XxjA' },
    { src: 'https://fastly.picsum.photos/id/867/800/800.jpg?hmac=D9jzjAep8_znPPeF-n0XXnzhVkM55GNOAUlGJDYoA5Q' },
    { src: 'https://fastly.picsum.photos/id/912/800/800.jpg?hmac=b4vsGu44_E8s9aABvkRZLIjZP81WYwWLynWFja51QPs' },
    { src: 'https://fastly.picsum.photos/id/386/800/800.jpg?hmac=0DwpqJ5VoJ4w4fByEBihC0FCqFYdCkA8K8oevlLir_A' }

  ];

  const swiperRef = useRef(null);


  const handleSwiperxx = (swiper: any) => {
    // Swiper örneğini kullanmak için burada işlemler yapabilirsiniz
    setThumbsSwiper(swiper);
    console.log('Swiper örneği:', swiper);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideClick = (index: any) => {
    setActiveIndex(index);
  };

  const handleFullscreenClick = () => {
    const element = document.getElementById(`slide-${activeIndex}`);
    if (element && element.requestFullscreen) {
      element.requestFullscreen();
    }
  };
  function resimbas() {
    return (
      <>
        <img className="col-12 rounded" src="https://source.unsplash.com/pAKCx4y2H6Q/1400x1200" />
        <img className="col-3 rounded" src="https://source.unsplash.com/AYS2sSAMyhc/1400x1200" />
        <img className="col-3 rounded" src="https://source.unsplash.com/Kk8mEQAoIpI/1600x1200" />
        <img className="col-3 rounded" src="https://source.unsplash.com/HF3X2TWv1-w/1600x1200" />
      </>
    )
  }

  return (
    <>
      <MainHeader />
      {/* h-300px */}
      {/* d-flex flex-column  flex-column-fluid bgi-position-y-bottom   bgi-position-x-center bgi-no-repeat bgi-size-fixed bgi-attachment-fixed */}
      <>
        <div className="d-flex  flex-column-fluid "
          style={{

            backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/4sayfa.svg`)})`,
            backgroundPosition: 'center', backgroundRepeat: 'repeat', backgroundSize: 'cover'
          }}>

          <div className="row w-100 mx-auto px-3" style={{ marginTop: '100px' }}>
            {content['iframe_page'] !== '' ? (<>
              <div className="col-12 px-20 ">
                {
                  <iframe

                    width={'100%'} height={'82%'}
                    style={{ background: '#F8FBFE', borderRadius: '8px', transition: ' all .2s', boxShadow: '12px 12px 2px 1px rgba(0, 0, 255, .2)' }}
                    className='rounded'
                    draggable={false}
                    //http://localhost/hotel_tanitim/api/iframe_getir  
                    // src={`${process.env.REACT_APP_DATABASE_API_URL}iframe_getir/}`}
                    src={`${content['iframe_page']}`}
                    //src={content['iframe_page']}
                    aria-hidden="false"
                  />}
              </div>
            </>) : (<>
              <div className="col-sm-12 col-md-3">
                <div className=" cardnewGalery " style={{ height: `${sttnaberlo}px` }}>
                  <div className="p-5">
                    {/* <SlideshowLightbox showThumbnails={true}  className="row">
                      <img className="col-12 rounded" src="https://source.unsplash.com/pAKCx4y2H6Q/1400x1200" />
                      <img className="col-3 rounded" src="https://source.unsplash.com/AYS2sSAMyhc/1400x1200" />
                      <img className="col-3 rounded" src="https://source.unsplash.com/Kk8mEQAoIpI/1600x1200" />
                      <img className="col-3 rounded" src="https://source.unsplash.com/HF3X2TWv1-w/1600x1200" />
                    </SlideshowLightbox> */}

                    <div className="row element-center" style={{ height: `${sttnaberlo}px`, width: 'auto' }}>
                      <img style={{ width: 'auto', height: '65px' }} className='mx-auto mb-3' src={toAbsoluteUrl('/media/logos/key-1.png')} />

                      <Swiper
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                      >

                        {images_list.map((image, indexc): any => (
                          <SwiperSlide>
                            <SlideshowLightbox showThumbnails={true}  slideshowInterval={5000} className="row">
                              {images_list.map((image, index): any => (
                                <img className={`${index === indexc ? "" : "d-none"} col-12 rounded`} src={image.original} />
                              ))}
                            </SlideshowLightbox>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <Swiper
                        onSwiper={handleSwiperxx}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                      >
                        {images_list.map((image, index) => (
                          <SwiperSlide>
                            <img src={image.original} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <img style={{ width: 'auto', height: '65px' }} className='mx-auto mt-3' src={toAbsoluteUrl('/media/logos/key-1.png')} />

                    </div>

                    {/* <div className="flex-center">
                      <div className="col-12 ms-3 rounded ">
                        <div className="row element-center" style={{ height: `${sttnaberlo}px`, width: 'auto' }}>
                          <img style={{ width: '80px', height: '80px' }} className='mx-auto mb-3' src={toAbsoluteUrl('/media/logos/key-1.png')} />
                          <ImageGallery lazyLoad={true} slideDuration={1000} items={images_list} />

                          <img style={{ width: '80px', height: '80px' }} className='mx-auto mt-3' src={toAbsoluteUrl('/media/logos/key-1.png')} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <div className="col-12">
                    <div className="d-none d-sm-block cardnewresim  bg-dark " style={{ height: `${sttnaberlo / 2.1}px`, textAlign: 'center', objectFit: 'cover' }} >
                      {content.local_video !== "" ?
                        <Videocum deger={content.local_video} /> :
                        content.iframe_video !== "" ?
                          (<iframe
                            allowFullScreen

                            width="100%" height={`${sttnaberlo / 2.1}px`}
                            src={content['iframe_video']}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          ></iframe>) :
                          (<img className=' ' style={{ maxWidth: '1050px' }} src={`${process.env.REACT_APP_MEDIA_API_URL}images/${content.icon}`} width="100%" height={`${sttnaberlo / 2.1}px`} />)
                      }
                    </div>
                  </div>
                  <div className="col-12 mt-4 " style={{ height: `${sttnaberlo / 2}px` }}>
                    <div className="mt-3 cardtext text-center">
                      <h1 style={{ fontFamily: 'Keiner-Medium' }} className='mt-4 '> {content.name}</h1>
                      <div className='scrollbar' id="style-2" style={{
                        width: '100%',
                        height: '80%',
                        overflow: 'auto',
                      }}>
                        {<div className='force-overflow ' style={{ fontSize: '25px', fontFamily: 'Keiner-Medium', whiteSpace: 'pre-line', width: '100%', maxHeight: '16rem' }}>
                          {content.text}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="cardnewharita" style={{ height: `${sttnaberlo}px` }}>
                  {
                    <iframe
                      className=' rounded'
                      draggable={false}
                      allowFullScreen
                      width={'100%'}
                      height={'100%'}
                      src={`${process.env.REACT_APP_DATABASE_API_URL}harita_getir/1/${content['map_lat']}/${content['map_lng']}/${content['map_zoom']}`}
                      aria-hidden="false"
                    />}
                </div>
              </div>
            </>)}
          </div>
        </div >
      </>
      <MainFooter />
    </>
  )
}

export default ContentPage