import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Cards } from '../components/Cards';
import MainFooter from '../components/MainFooter';
import MainHeader from '../components/MainHeader';
import { motion } from 'framer-motion'
function SubTitlePage() {

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  const [components, setComponents] = useState([]);
  const getData = () => {
    axios.post(`${process.env.REACT_APP_DATABASE_API_URL}subtitle/subtitle_list_wid/${titleId}`)
      .then(res => res.data)
      .then(data => {
        setComponents(data['data']);

      })
  }
  useEffect(() => {
    getData()
  }, [])

  let { titleId } = useParams();
  return <>
    <MainHeader />
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='d-flex flex-column   flex-column-fluid '
      style={{
        backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/2sayfa.webp`)})`,
        backgroundPosition: 'center', backgroundRepeat: 'repeat', backgroundSize: 'cover'
      }} >
      <div className="text-center text-white   mt-20" >
        <div className="row mx-auto  d-flex justify-content-center mt-20" >
          {components.map((val: any, i: any) => (
            <div key={i} className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2 mb-10 " >
              <Cards baslik={`${val['name']}`}
                sira={val['id']}
                img={`${val['icon']}`}
                url={`${val['id']}${val['sub_title_active'] === 0 ? '' : '/0'}`}
                className='shadow w-75  mt-5 '
                chartColor='primary'
                chartHeight='#fff'
              />
            </div>
          ))}
        </div>
      </div>
      <MainFooter />
    </motion.div>
  </>
}

export default SubTitlePage

