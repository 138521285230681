import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Cards } from '../components/Cards';
import MainFooter from '../components/MainFooter';
import MainHeader from '../components/MainHeader';
import { motion } from 'framer-motion'
function SubTitleContentPage() {
  let { subtitleId } = useParams();
  let { titleId } = useParams();
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  const [components, setComponents] = useState([]);
  const getData = () => {
    axios.get(`${process.env.REACT_APP_DATABASE_API_URL}contents/contents_list_guest/${titleId}/${subtitleId}`)
      .then(res => res.data)
      .then(data => {
        setComponents(data['data']);
      })
  }
  useEffect(() => {
    getData()
  }, [])

  return <>
    <MainHeader />
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='d-flex flex-column   flex-column-fluid '
      style={{
        backgroundImage: `url(${toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}/resimler/3sayfa.webp`)})`,
        backgroundPosition: 'center', backgroundRepeat: 'repeat', backgroundSize: 'cover'
      }} >
      <div className="text-center text-white   mt-20" >
        <div className="row mx-auto  d-flex justify-content-center mt-8" >
          {components.map((val: any, i) => {
            return (
              <div key={i} className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3    " >
                <Cards
                  baslik={`${val['name']}`}
                  sira={val['id']}
                  img={val['local_image'] === null ? '' : `images/${val['icon']}`}
                  url={`${val['id']}${val['sub_title_active'] === 0 ? '' : '/0'}`}
                  statement={`${val['statement']}`}
                  className=' w-75  px-0 '
                  chartColor='primary'
                  chartHeight='#fff' />
              </div>
            )
          }
          )}
        </div>

      </div>
      <MainFooter />
    </motion.div>
  </>
}

export default SubTitleContentPage
