import IconWeatherComponent from './IconWeatherComponent'
import QRCode from 'react-qr-code'
const Weather = ({ weatherData }) => {
  function isimci(deger) {
    if (deger == 1) {
      return "#fff";
    } else if (deger == 2) {
      return "#e0a800"
    } else {
      return "#e0a800";
    }
  }
  const webtur = isimci(process.env.REACT_APP_WEBTUR);
  return (
    <>
      {/* {weatherData.name} | */}

      <div className='d-flex justify-content-between'>
        <div className='mt-2 me-5'>
          <QRCode size={60} style={{ width: '60px', height: '60px' }} value={window.location.href} />
        </div>

        <div className='text-center'>
          <b style={{ fontSize: '25px', fontFamily: 'Keiner-Medium', color: webtur }}>
            {weatherData.name} <br />
          </b>
          <span style={{ fontFamily: 'Keiner-Medium', fontSize: '15px', color: webtur }} className='text-center'>
            {' '}
            Weather
          </span>
        </div>
        <div className=''>
          <span>
            <IconWeatherComponent icon={weatherData.icon} />
          </span>
        </div>
        <div className='text-center'>
          <b style={{ fontSize: '25px', fontFamily: 'Keiner-Medium', color: webtur }}>
            {weatherData.temp ? weatherData.temp : ''}°C <br />
          </b>
          <span style={{ fontFamily: 'Keiner-Medium', fontSize: '15px', color: webtur }} className=''>
            {weatherData.description}
          </span>
        </div>
        <div className=''></div>
      </div>
    </>
  )
}

export default Weather
