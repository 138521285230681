/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  MixedWidget8,
  TilesWidget1,
  TilesWidget4,
  TilesWidget2,
  TilesWidget5,
  StatisticsWidget2,
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {

  const [Components, setComponents] = useState<any>();
  
  const getData = () => {
    axios.get(`${process.env.REACT_APP_DATABASE_API_URL}cards/counts_all_components`)
      .then((res:any) => { setComponents(res.data);
      
      })
      
  }
useEffect(() => {
  getData()
}, [])


return (
  <>
    <div className='row g-5 g-xl-8'>
      {/* <div className='col-xl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div> */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar='/media/dashboard/title.png'
            title='ANA BAŞLIKLAR'
            description={`Toplamda ${Components===undefined?'0':Components[0]} adet ana başlık bulunmaktadır`}
            link='cards/components/card'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget2
            link='cards/components/subtitle'
            className='card-xl-stretch mb-xl-8'
            avatar='/media/dashboard/subtitle1.png'
            title='ALT BAŞLIKLAR'
            description={`Toplamda ${Components===undefined?'0':Components[1]} adet alt başlık bulunmaktadır`}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget2
            link='cards/components/twosubtitle'
            className='card-xl-stretch mb-xl-8'
            avatar='/media/dashboard/subtitle1.png'
            title='2. ALT BAŞLIKLAR'
            description={`Toplamda ${Components===undefined?'0':Components[3]} adet 2. Alt başlık bulunmaktadır`}
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget2
            link='cards/components/content'
            className='card-xl-stretch mb-5 mb-xl-8'
            avatar='/media/dashboard/content.png'
            title='İÇERİK'
            description={`Toplamda ${Components===undefined?'0':Components[2]} adet içerik bulunmaktadır`}
          />
        </div>
        <div className='col-xl-4'>
          <StatisticsWidget2
            link='guest/backgorund'
            className='card-xl-stretch mb-5 mb-xl-8'
            avatar='/media/dashboard/background.png'
            title='ARKA PLAN FOTOĞRAFLARI'
            description={`Ana sayfadaki resimleri bu alandan değiştirebilirsiniz.`}
          />
        </div>
      </div>

    </div>

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      {/* <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div> */}
      {/* <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}
      {/* <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'> */}
    {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div> */}
    {/* <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div> */}
    {/* </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      {/* <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div> */}
      {/* <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div> */}
      {/* <div className='col-xl-4'> */}
      {/* <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} /> */}
      {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      {/* </div> */}
    </div>
    {/* end::Row */}
  </>)
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
